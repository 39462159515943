import React, { useState, useEffect } from 'react';
import {
  CardsContainer,
  FormSection,
  ContentSection,
  ContentSectionSearch,
  Button,
  RouterLink,
  CardFooter,
  CardToolbar,
  AsideLeftTableSection,
  AsideLeftTableGroupSection,
  LoadingScreen,
  ModalNotification
} from '../../components';
import tableGroupLogo from '../../assets/img/database_2.svg';
import userLogo from '../../assets/img/users.svg';
import tableLogo from '../../assets/img/database_1.svg';
import { disableUserAccess } from '../../constants/featureFlags';

const tealTheme = { primary: 'black', secondary: 'white' };

const TablePrivLayout = props => {
  const [value, setValue] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const { selectedTable, selectedTableGroup } = props;

  useEffect(() => {
    const fetchData = async () => {
      setIsBusy(true);
      await props.getTableGroups();
      await props.setSelectedTableGroup(props.match.params.tableGroupId);
      await props.setSelectedTable(
        props.match.params.tableGroupId,
        props.match.params.tableId
      );
      setIsLoaded(true);
      setIsBusy(false);
    };
    if (isLoaded === false) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If it is not loaded the the Loading screen is rendered
  if (isLoaded === false) return <LoadingScreen isLoading={true} />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!props.selectedTableGroup && isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => props.history.push('/tablegroup')}
      />
    );
  }

  // If the level is Table  and no table has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroup details
  if (isLoaded === true && !selectedTable) {
    const notification = {
      isNotification: true,
      title: 'Table not found',
      message: 'No Table was found with the specified table id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() =>
          props.history.push(`/tablegroup/${selectedTableGroup?.name}`)
        }
      />
    );
  }

  // ------------------------------------------------------------------
  // At this point all the data needed to render this layout is in place
  // ------------------------------------------------------------------

  // ------------------------------------------------------------------
  // At this point everything  is in place and the user has access for sure to this resource
  // ------------------------------------------------------------------

  const searchUsers = () =>
    props.tablePrivileges.filter(privilege => {
      return privilege.userIdentifier
        .toUpperCase()
        .includes(value.toUpperCase());
    });

  const getPrivilegesToDisplay = searhValue =>
    searhValue?.trim() === '' ? props.tablePrivileges : searchUsers();

  const privilegesToDisplay =
    props?.tablePrivileges?.length > 0 ? getPrivilegesToDisplay(value) : [];

  let showPrivilegeActions = false;

  if (
    props.selectedTable?.role === 'ADMIN' ||
    (props.selectedTable?.role === 'OWNER' && !disableUserAccess)
  ) {
    showPrivilegeActions = true;
  }

  const cards =
    privilegesToDisplay.length > 0
      ? privilegesToDisplay.map((privilege, i) => ({
          title: privilege.userIdentifier,
          subtitle: `${privilege.userRole} ${
            privilege.inherited === true ? ' ( inherited )' : ''
          }`,
          width: '290px',
          height: '82px',
          iconPath: userLogo,
          children: (
            <CardFooter
              rightSection={
                <CardToolbar
                  key={privilege.userIdentifier}
                  showEdit={showPrivilegeActions}
                  redirectWhenEdit={true}
                  redirectEditPath={`/tablegroup/${selectedTableGroup?.name}/table/${selectedTable?.name}/privileges/${privilege.userIdentifier}`}
                  showDelete={!privilege.inherited && showPrivilegeActions}
                  redirectWhenDelete={false}
                  onDeleteClick={() => handleDeleteClick(privilege)}
                />
              }
            />
          )
        }))
      : [];

  const AsideRightTableSection = (
    <div className="right-aside-wrapper">
      {showPrivilegeActions && (
        <RouterLink
          href={`/tablegroup/${selectedTableGroup?.name}/table/${selectedTable?.name}/privileges/create`}
        >
          <Button theme={tealTheme} small>
            Add Privileges
          </Button>
        </RouterLink>
      )}
      <RouterLink
        href={`/tablegroup/${selectedTableGroup?.name}/table/${selectedTable?.name}/replicationtargets`}
      >
        <Button theme={tealTheme} small>
          Replication Targets
        </Button>
      </RouterLink>
    </div>
  );

  const AsideLeftPrivilegeSection = (
    <div className="table-groups-tiles">
      <div className="table-groups-tiles-div">
        <div className="table-groups-counter">{privilegesToDisplay.length}</div>
      </div>
    </div>
  );

  const handleDeleteClick = privilege => {
    const confirmation = {
      acceptCallback: () => {
        props.deletePrivilege(privilege);
      },
      cancelCallback: () => {
        props.removeConfirmation();
      }
    };

    props.setOnDeleteRecordConfirmation(confirmation);
  };

  return (
    <div className="container-layout-wrapper">
      <LoadingScreen isLoading={isBusy} />
      <ContentSection
        logo={tableGroupLogo}
        title={selectedTableGroup?.name}
        description={selectedTableGroup?.description}
        asideLeft={
          <AsideLeftTableGroupSection
            totalCount={selectedTableGroup?.tablesCount}
            totalTableGroupPrivileges={selectedTableGroup?.privilegesCount}
            currentUserTableGroupRole={selectedTableGroup?.role}
          />
        }
        asideLeftClassName="content-section-col-statistic"
        redirectWhenContentMiddleClick
        redirectContentMiddlePath={`/tablegroup/${selectedTableGroup?.name}`}
      />
      <ContentSection
        logo={tableLogo}
        title={selectedTable?.name}
        description={selectedTable?.description}
        asideLeft={
          <AsideLeftTableSection
            selectedTable={selectedTable}
            currentUserTableRole={selectedTable?.role}
            totalTablePrivileges={selectedTable?.usersCount}
          />
        }
        asideRight={AsideRightTableSection}
        asideLeftClassName="content-section-col-statistic"
        redirectWhenContentMiddleClick
        redirectContentMiddlePath={`/tablegroup/${selectedTableGroup?.name}/table/${selectedTable?.name}`}
      />
      <ContentSectionSearch
        logo={userLogo}
        title="Privileges"
        value={value}
        handleChange={val => setValue(val)}
        asideLeft={AsideLeftPrivilegeSection}
        asideLeftClassName="content-section-col-statistic"
      />
      <FormSection className="container-layout-body">
        <CardsContainer
          cards={cards}
          classContainer="card-container-table small"
          classTitle="card-container-priv-table-group-custom-title-row "
          classCard="card-container-priv-table-group"
          redirectWhenClick={false}
        />
      </FormSection>
    </div>
  );
};

export default TablePrivLayout;
