// react
import React, { useState, useEffect } from 'react';
// components
import {
  CardsContainer,
  FormSection,
  ContentSection,
  ContentSectionSearch,
  Button,
  RouterLink,
  CardFooter,
  CardToolbar,
  LoadingScreen,
  ModalNotification
} from '../../components';
// assets
import tableGroupLogo from '../../assets/img/database_2.svg';
import userLogo from '../../assets/img/users.svg';
// css
import './tableGroupPrivLayout.css';
import { disableUserAccess } from '../../constants/featureFlags';

const tealTheme = { primary: 'black', secondary: 'white' };

const TableGroupPrivLayout = props => {
  const [value, setValue] = useState('');
  const [isBusy, setIsBusy] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const { selectedTableGroup } = props;

  useEffect(() => {
    const fetchData = async () => {
      setIsBusy(true);
      await props.getTableGroups();
      await props.setSelectedTableGroup(props.match.params.tableGroupId);
      setIsLoaded(true);
      setIsBusy(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If it is not loaded the the Loading screen is rendered
  if (isLoaded === false) return <LoadingScreen isLoading={true} />;

  // If no tablegroup has been found with the specified parameters
  // then a Modal  notification is rendered and on click
  // the user must be redirected to the tablegroups overview
  if (!selectedTableGroup && isLoaded === true) {
    const notification = {
      isNotification: true,
      title: 'Table Group not found',
      message: 'No Table Group was found with the specified table group id.',
      isModal: true,
      showIcon: true
    };
    return (
      <ModalNotification
        notification={notification}
        removeNotification={() => props.history.push('/tablegroup')}
      />
    );
  }

  // ------------------------------------------------------------------
  // At this point all the data needed to render this layout is in place
  // ------------------------------------------------------------------

  // It is necessary to determine the user's role
  const currentUserRole = selectedTableGroup?.role;

  // ------------------------------------------------------------------
  // At this point everything  is in place and the user has access for sure to this resource
  // ------------------------------------------------------------------
  const searchUsers = () =>
    props?.tableGroupPrivileges?.filter(privilege => {
      return privilege.userIdentifier
        .toUpperCase()
        .includes(value.toUpperCase());
    });

  const getPrivilegesToDisplay = searhValue =>
    searhValue?.trim() === '' ? props.tableGroupPrivileges : searchUsers();

  const privilegesToDisplay =
    props?.tableGroupPrivileges?.length > 0
      ? getPrivilegesToDisplay(value)
      : [];

  // const isViewPrivilleges = props.selectedTableGroup?.role ===
  let showPrivilegeActions = false;

  if (
    props.selectedTableGroup?.role === 'ADMIN' ||
    (props.selectedTableGroup?.role === 'OWNER' && !disableUserAccess)
  ) {
    showPrivilegeActions = true;
  }

  const cards =
    privilegesToDisplay?.length > 0
      ? privilegesToDisplay.map((privilege, i) => ({
          title: privilege.userIdentifier,
          subtitle: `${privilege.userRole} ${
            privilege.inherited === true ? ' ( inherited )' : ''
          }`,
          width: '290px',
          height: '82px',
          iconPath: userLogo,
          children: (
            <CardFooter
              rightSection={
                <CardToolbar
                  key={privilege.userIdentifier}
                  showEdit={showPrivilegeActions}
                  redirectWhenEdit={true}
                  redirectEditPath={`/tablegroup/${selectedTableGroup?.name}/privileges/${privilege.userIdentifier}`}
                  toolTipEdit="Edit privileges"
                  toolTipDelete="Delete privileges"
                  showDelete={!privilege.inherited && showPrivilegeActions}
                  redirectWhenDelete={false}
                  onDeleteClick={() => handleDeleteClick(privilege)}
                />
              }
            />
          )
        }))
      : [];

  const AsideLeftTableGroupSection = (
    <div className="table-groups-tiles">
      <div className="table-groups-tiles-div">
        <div className="table-groups-counter">
          {selectedTableGroup?.tablesCount}
        </div>
        <span className="content-section-row-title-grey">Tables</span>
      </div>
      <div className="table-groups-tiles-div">
        <div className="table-groups-counter">
          {selectedTableGroup?.privilegesCount}
        </div>
        <div className="content-section-row-title-grey">Privileges</div>
      </div>
      <div className="table-groups-tiles-div">
        <div className="table-groups-counter">{currentUserRole}</div>
        <div className="content-section-row-title-grey">role</div>
      </div>
    </div>
  );

  const AsideRightTableGroupSection = (
    <div className="right-aside-wrapper">
      {showPrivilegeActions && (
        <RouterLink
          href={`/tablegroup/${selectedTableGroup?.name}/privileges/create`}
        >
          <Button theme={tealTheme} small>
            Add Privileges
          </Button>
        </RouterLink>
      )}
    </div>
  );

  const AsideLeftPrivilegeSection = (
    <div className="table-groups-tiles">
      <div className="table-groups-tiles-div">
        <span className="table-groups-counter">
          {privilegesToDisplay.length}
        </span>
      </div>
    </div>
  );

  const handleChange = val => {
    setValue(val);
  };

  const handleDeleteClick = privilege => {
    const confirmation = {
      acceptCallback: () => {
        props.deletePrivilege(privilege);
      },
      cancelCallback: () => {
        props.removeConfirmation();
      }
    };

    props.setOnDeleteRecordConfirmation(confirmation);
  };

  return (
    <div className="container-layout-wrapper">
      <LoadingScreen isLoading={isBusy} />
      <ContentSection
        logo={tableGroupLogo}
        title={selectedTableGroup?.name}
        description={selectedTableGroup?.description}
        asideLeft={AsideLeftTableGroupSection}
        asideRight={AsideRightTableGroupSection}
        asideLeftClassName="content-section-col-statistic"
        redirectWhenContentMiddleClick
        redirectContentMiddlePath={`/tablegroup/${selectedTableGroup?.name}`}
      />
      <ContentSectionSearch
        logo={userLogo}
        title="Privileges"
        value={value}
        handleChange={handleChange}
        asideLeft={AsideLeftPrivilegeSection}
        asideLeftClassName="content-section-col-statistic"
      />
      <FormSection className="container-layout-body">
        <CardsContainer
          cards={cards}
          classContainer="card-container-table medium"
          classTitle="card-container-priv-table-group-custom-title-row"
          classCard="card-container-priv-table-group"
          redirectWhenClick={false}
        />
      </FormSection>
    </div>
  );
};

export default TableGroupPrivLayout;
